.App {
  position: fixed;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  overflow: auto;
  height: 100%;
}

.d_hotline {
  display: none !important;
}

.backgroundImageContainer {
  width: 100vw;
  height: 100vh;
  background-image: url('./images/Entry/428-926-mobile.png');
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  background-color: #F8F8F8;
  @media screen and (max-width: 400px) {
      background-image: none;
  }
  @media screen and (min-width: 767px) {background-image: url('./images/Entry/768-1024.png');}
  @media screen and (min-width: 1140px) {background-image: url('./images/Entry/1152-700.png');}
  @media screen and (max-width: 428px) and (min-width: 767px) {background-image: url('./images/Entry/428-926-mobile.png');}
}